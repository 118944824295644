enum NotificationTypeEnum {
  NEW_ORDER = 'NEW_ORDER',
  NEW_PRODUCT = 'NEW_PRODUCT',
  UPDATE_PRODUCT = 'UPDATE_PRODUCT',
  NEW_CATEGORY = 'NEW_CATEGORY',
  UPDATE_CATEGORY = 'UPDATE_CATEGORY',
}

enum NotificationDataStatus {
  PENDING = 'PENDING',
  IDLE = 'IDLE',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

export { NotificationTypeEnum, NotificationDataStatus };
