import React, { Dispatch, forwardRef, SetStateAction, useContext, useState } from 'react';
import { Button, makeStyles, TextField } from '@material-ui/core';
import DatePicker, { registerLocale } from 'react-datepicker';
import { useDispatch, useSelector } from 'react-redux';

import 'react-datepicker/dist/react-datepicker.css';
import './costomPickerStyle.scss';

import { AppDispatch, RootState } from '../../store/store';

import { getOrderByRangeRequest, getOrderStatusByRangeRequest } from '../../store/actions/orders.actions';
import { getCommentsByRangeRequest } from '../../store/actions/comments.actions';
import { getUsersDateRangeRequest } from '../../store/actions/users.actions';
import { getSlidesByRangeRequest, fetchSlides } from '../../store/actions/slides.actions';
import { COLORS } from '../../values/colors';

import uk from 'date-fns/locale/uk';
import { StatisticActionContext } from '../../context/staistic-action-context';
import { LabelEnum } from './labelEnum';
import { Autocomplete } from '@material-ui/lab';

registerLocale('uk', uk);

enum datePosition {
  start = 'start',
  stop = 'stop',
}

enum btnTarget {
  status = 'status',
  order = 'order',
  users = 'users',
  comments = 'comments',
  slides = 'slides',
}

const useStyles = makeStyles({
  'dateSections': {
    display: 'block',
    width: '100%',
    marginBottom: '50px',
  },
  'datePickers': {
    'display': 'flex',
    'width': 'fit-content',
    'margin': '15px auto 0 auto',
    'gap': '15px',
    '& > div': {
      marginRight: '0',
    },
    '& .react-datepicker__day--selected ': {
      'background-color': COLORS.primaryGreen,
    },
  },
  'datePickersDark': {
    'display': 'flex',
    'width': 'fit-content',
    'margin': '15px auto 0 auto',
    'gap': '15px',
    '& > div': {
      'marginRight': '0',
    },
    '& .react-datepicker__header': {
    'background-color': COLORS.darkGreen
    },
    '& .react-datepicker__month-container': {
      'background-color': COLORS.secondaryDarkGray,
    },
    '& .react-datepicker__day--selected ': {
      'background-color': COLORS.darkGreen,
    },
  },
  'buttonsSection': {
    width: '100%',
  },
  'buttonsList': {
    listStyle: 'none',
    display: 'flex',
    gap: '15px',
    width: 'fit-content',
    margin: '30px auto 0 auto',
    padding: '0',
  },
  '@media(max-width: 475px)': {
    buttonsList: {
      flexDirection: 'column',
      justifyContent: 'center',
    },
  },
  'buttonItem': {
    'width': '100%',
    'backgroundColor': COLORS.primaryGreen,
    'color': COLORS.primaryLight,
    'borderRadius': '30px',
    '&:hover': {
      backgroundColor: COLORS.secondaryGreen,
    },
  },
  'buttonItemDark': {
    'backgroundColor': COLORS.darkGreen,
    'color': COLORS.primaryLight,
    '&:hover': {
      backgroundColor: COLORS.secondaryDarkGreen,
    },
  },
  'button': {
    width: '150px',
    'color': COLORS.primaryLight,
  },
  'ordersMenu': {
    position: 'relative',
  },
  'ordersButtons': {
    '@media(max-width: 475px)': {
      transition: 'all 0.3s ease-in-out',
      display: 'flex',
      justifyContent: 'center',
      gap: '15px',
    },
  },
  'ordersButtonOne': {
    width: '190px',
    marginTop: '15px',
    marginRight: '7px',
    animation: '$AppearanceOne 1s',
    position: 'absolute',
    transform: 'translate(-50% , 0%)',
    color: COLORS.primaryLight,
  },
  '@keyframes AppearanceOne': {
    '0%': {
      opacity: 0,
      transform: 'translate(10% , -130%)',
    },
    '100%': {
      opacity: 1,
      transform: 'translate(-50% , 0%)',
    },
  },
  'ordersButtonTwo': {
    width: '190px',
    marginTop: '15px',
    marginLeft: '7px',
    animation: '$AppearanceTwo 1s',
    position: 'absolute',
    transform: 'translate(50% , 0%)',
  },

  '@keyframes AppearanceTwo': {
    '0%': {
      opacity: 0,
      transform: 'translate(-10% , -130%)',
    },
    '100%': {
      opacity: 1,
      transform: 'translate(50%, 0%)',
    },
  },

  'orders': {
    'textAlign': 'center',
    'width': '150px',
    'cursor': 'pointer',
    'color': 'white',
    'padding': '8px 15px 8px 15px',
    'fontWeight': 500,
    'textTransform': 'uppercase',
    'backgroundColor': COLORS.primaryGreen,
    'borderRadius': '30px',
    'boxShadow':
      '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
    '&:hover': {
      backgroundColor: COLORS.secondaryGreen,
    },
  },
  'ordersDark': {
    'backgroundColor': COLORS.darkGreen,
    '&:hover': {
      backgroundColor: COLORS.secondaryDarkGreen,
    },
  },
  'orderTransition': {
    '@media(max-width: 475px)': {
      marginBottom: '51px',
      transition: 'all 700ms ease-in-out',
    },
  },
  'selectSection': {
    'outline': `1px solid ${COLORS.primaryGreen}`,
    'backgroundColor': COLORS.primaryGreen,
    'border': 'none',
    'borderRadius': '30px',
    'fontSize': '0.875rem',
    'fontFamily': '"Roboto", "Helvetica", "Arial", sans-serif',
    'fontWeight': 500,
    'lineHeight': '1.75',
    'letterSpacing': '0.02857em',
    'padding': '6px 16px',
    'color': COLORS.primaryLight,
    'boxShadow':
      '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
    '&:hover': {
      backgroundColor: COLORS.secondaryGreen,
    },
  },
  'selectSectionDark': {
    'outline': `1px solid ${COLORS.darkGreen}`,
    'backgroundColor': COLORS.darkGreen,
    '&:hover': {
      backgroundColor: COLORS.secondaryDarkGreen,
    },
  },
  'autocomplete': {
    width: '250px',
    marginTop: '15px',
    marginRight: '7px',
    animation: '$AppearanceThree 1s',
    position: 'absolute',
    transform: 'translate(-20%, 5%)',
  },
  '@keyframes AppearanceThree': {
    '0%': {
      opacity: 0,
      transform: 'translate(0% , -130%)',
    },
    '100%': {
      opacity: 1,
      transform: 'translate(-20% , 5%)',
    },
  },
});

interface IDataChart {
  date: string;
  value: string;
  valueSecond?: string;
}
interface IStatisButtons {
  chartData: IDataChart[] | null;
  setChartData: Dispatch<SetStateAction<IDataChart[] | null>>;
}

const StatisticsButtons: React.FC<IStatisButtons> = ({ chartData, setChartData }) => {
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [endDate, setEndDate] = useState<Date>(new Date());

  const { setAction } = useContext(StatisticActionContext);

  const darkMode = useSelector((state: RootState) => state.theme.darkMode);
  const dispatch: AppDispatch = useDispatch();
  const styles = useStyles();

  const slides = useSelector((state: RootState) => state.slides.list);

  const [additonalMenuStatus, setAdditonalMenuStatus] = useState<'close' | 'orders' | 'slides'>('close');

  const slideOptions =
    additonalMenuStatus === 'slides' && slides && slides.length > 0
      ? [
          { label: 'Усі слайди', id: -1 },
          ...slides.map((item) => ({ label: ` ID: ${item.id} - ${item.name}`, id: item.id })).toReversed(),
        ]
      : [{ label: 'Усі слайди', id: -1 }];

  const onDateChangeHandler = (date: any, position: string) => {
    position === datePosition.start ? setStartDate(date) : setEndDate(date);
  };

  const onClickHandle = (target: string, id?: number) => {
    let afterEndDate = new Date(endDate);
    afterEndDate.setDate(afterEndDate.getDate() + 1);

    const formatedStartDate = startDate!.toISOString().split('T')[0];
    const formatedEndDate = afterEndDate!.toISOString().split('T')[0];

    switch (target) {
      case btnTarget.order:
        setChartData(null);
        setAction(LabelEnum.orders);
        return dispatch(getOrderByRangeRequest([formatedStartDate, formatedEndDate]));
      case btnTarget.status:
        setChartData(null);
        setAction(LabelEnum.status);
        return dispatch(getOrderStatusByRangeRequest([formatedStartDate, formatedEndDate]));
      case btnTarget.users:
        setChartData(null);
        setAction(LabelEnum.user);
        return dispatch(getUsersDateRangeRequest([formatedStartDate, formatedEndDate]));
      case btnTarget.comments:
        setChartData(null);
        setAction(LabelEnum.comments);
        return dispatch(getCommentsByRangeRequest([formatedStartDate, formatedEndDate]));
      case btnTarget.slides:
        setChartData(null);
        setAction(LabelEnum.slides);
        return dispatch(getSlidesByRangeRequest([formatedStartDate, formatedEndDate], id || undefined));
    }
  };

  const ExampleCustomInput = forwardRef<HTMLButtonElement, { value: any; onClick: any }>(
    ({ value, onClick }, ref) => (
      <button
        className={`${styles.selectSection} ${darkMode && styles.selectSectionDark}`}
        onClick={onClick}
        ref={ref}
      >
        {value}
      </button>
    )
  );

  return (
    <div className={styles.dateSections}>
      <div className={darkMode ? styles.datePickersDark : styles.datePickers}>
        <DatePicker
          locale="uk"
          selected={startDate}
          onChange={(date) => onDateChangeHandler(date, datePosition.start)}
          customInput={<ExampleCustomInput value={undefined} onClick={undefined} />}
          dateFormat="dd.MM.yyyy"
          maxDate={new Date() || endDate}
        />
        <DatePicker
          locale="uk"
          selected={endDate}
          onChange={(date) => onDateChangeHandler(date, datePosition.stop)}
          customInput={<ExampleCustomInput value={undefined} onClick={undefined} />}
          dateFormat="dd.MM.yyyy"
          minDate={startDate}
          maxDate={new Date()}
        />
      </div>
      <div className={styles.buttonsSection}>
        <ul className={styles.buttonsList}>
          <div className={additonalMenuStatus === 'orders' ? styles.orderTransition : 'none'}>
            <li
              className={`${styles.orders} ${darkMode && styles.ordersDark}`}
              onClick={() => {
                if (additonalMenuStatus !== 'orders') setAdditonalMenuStatus('orders');
                else setAdditonalMenuStatus('close');
              }}
            >
              Замовлення
            </li>
            {additonalMenuStatus === 'orders' && (
              <div className={styles.ordersButtons}>
                <div className={styles.ordersButtonOne}>
                  <Button
                    className={`${styles.buttonItem} ${darkMode && styles.buttonItemDark}`}
                    id="order"
                    variant="contained"
                    color="primary"
                    type="button"
                    onClick={() => onClickHandle(btnTarget.status)}
                  >
                    Статус замовлення
                  </Button>
                </div>
                <div className={styles.ordersButtonTwo}>
                  <Button
                    className={`${styles.buttonItem} ${darkMode && styles.buttonItemDark}`}
                    id="order"
                    variant="contained"
                    color="primary"
                    type="button"
                    onClick={() => onClickHandle(btnTarget.order)}
                  >
                    Статус оплати
                  </Button>
                </div>
              </div>
            )}
          </div>
          <li>
            <Button
              className={`${styles.buttonItem} ${styles.button} ${darkMode && styles.buttonItemDark}`}
              id="users"
              variant="contained"
              color="primary"
              type="button"
              onClick={() => {
                setAdditonalMenuStatus('close');
                onClickHandle(btnTarget.users);
              }}
            >
              Користувачі
            </Button>
          </li>
          <li>
            <Button
              className={`${styles.buttonItem} ${styles.button} ${darkMode && styles.buttonItemDark}`}
              id="comments"
              variant="contained"
              color="primary"
              type="button"
              onClick={() => {
                setAdditonalMenuStatus('close');
                onClickHandle(btnTarget.comments);
              }}
            >
              Коментарі
            </Button>
          </li>
          <li style={{ position: 'relative' }}>
            <Button
              className={`${styles.buttonItem} ${styles.button} ${darkMode && styles.buttonItemDark}`}
              id="slides"
              variant="contained"
              color="primary"
              type="button"
              onClick={() => {
                if (additonalMenuStatus !== 'slides') {
                  dispatch(fetchSlides(1, 9999999));
                  setAdditonalMenuStatus('slides');
                } else setAdditonalMenuStatus('close');
              }}
            >
              Слайди
            </Button>
            {additonalMenuStatus === 'slides' && (
              <Autocomplete
                disablePortal
                id="slides-statistic"
                className={styles.autocomplete}
                options={slideOptions}
                getOptionLabel={(option) => option.label}
                renderInput={(params) => <TextField {...params} label={'Оберіть слайд'} />}
                onChange={(e, value, reason) => {
                  if (reason === 'select-option' && value?.id) {
                    const id = value.id > 0 ? value.id : undefined;
                    onClickHandle(btnTarget.slides, id);
                  }
                }}
              />
            )}
          </li>
        </ul>
      </div>
    </div>
  );
};

export default StatisticsButtons;
