import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Dashboard from '../../pages/Dashboard/Dashboard';
import Products from '../../pages/Products/ProductsPage';
import Statistic from '../../pages/Statistic/Statistic';
import Users from '../../pages/Users/Users';
import Slides from '../../pages/Slides/Slides';
import Settings from '../../pages/Settings/Settings';
import ViewProduct from '../../pages/Products/ProductRouter';
import AddProduct from '../Forms/Products/AddProduct/AddProduct';
import TreeCategoryRouter from '../../pages/TreeCategories/TreeCategoryRouter';
import OrdersPage from '../../pages/Orders/OrdersPage';
import OrderRouter from '../../pages/Orders/OrderRouter';
import DrawsPage from '../../pages/Draws/DrawsPage';
import TreeCategories from '../../pages/TreeCategories/TreeCategories';
import Login from '../../pages/Login/Login';
import PrivateRoute from './PrivateRoute';
import CommentsPage from '../../pages/Comments/CommentsPage';
import FeedbacksPage from '../../pages/Feedbacks/FeedbacksPage';
import Search from '../../pages/Search/Search';
import ResetPassword from '../../pages/ResetPassword/ResetPassward';
import Invoices from '../../pages/Invoices/Invoices';
import Profile from '../../pages/Profile/Profile';
import ConfirmEmail from './../../pages/ConfirmEmail/ConfirmEmail';
import ParsersPage from '../../pages/Parsers/ParsersPage';

const RouteSwitch: React.FC = () => {
  return (
    <Switch>
      <PrivateRoute path="/dashboard" component={Dashboard} />
      <PrivateRoute path="/tree-categories" exact={true} component={TreeCategories} />
      <PrivateRoute path="/products/" exact={true} component={Products} />
      <PrivateRoute path="/statistic" component={Statistic} />
      <PrivateRoute path="/invoices" component={Invoices} />
      <PrivateRoute path="/users" component={Users} />
      <PrivateRoute path="/slides" component={Slides} />
      <PrivateRoute path="/comments" component={CommentsPage} />
      <PrivateRoute path="/feedbacks" component={FeedbacksPage} />
      <PrivateRoute path="/settings" component={Settings} />
      <PrivateRoute path="/product/add" exact={true} component={AddProduct} />
      <PrivateRoute path="/orders" component={OrdersPage} />
      <PrivateRoute path="/draws" component={DrawsPage} />
      <PrivateRoute path="/parsers" component={ParsersPage} />
      <PrivateRoute component={ViewProduct} path="/product/:id" />
      <PrivateRoute component={TreeCategoryRouter} path="/tree-category/:id" />
      <PrivateRoute component={Search} path="/search" />
      <PrivateRoute component={OrderRouter} path="/order/:id" />
      <PrivateRoute component={Profile} path="/profile" />
      <PrivateRoute path="/password" component={ResetPassword} />
      <PrivateRoute path="/account/email/change" component={ConfirmEmail} />
      <Route path="/login" component={Login} />
    </Switch>
  );
};

export default RouteSwitch;
