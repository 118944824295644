import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Grid, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import { getAllNotification, notificationRead } from '../../../store/actions/notifications.action';
import countUnreadNotifications from '../../../utils/count-unread-notifications';
import isUserReadNotification from '../../../utils/is-user-read-notification';
import localeDateDiff from '../../../utils/locale-date-diff';
import { useHistory } from 'react-router-dom';
import { INotificationState } from '../../../interfaces/INotification';
import currentUTCDate from '../../../utils/currentUTCDate';
import ReceiptIcon from '@material-ui/icons/Receipt';
import useBodyClick from '../../../hooks/useBodyClick';
import SimpleBar from 'simplebar-react';
import useWindowDimensions from '../../../hooks/useWindowDimension';
import countHtmlElementsHeightSum from '../../../utils/count-html-elements-height-sum';

interface NotificationListProps {
  noticeAnchorEl: null | Element;
  onNoticeListClose: () => void;
}

const useStyles = makeStyles((theme: any) => ({
  dropdown: {
    width: '20rem',
    fontSize: '15px',
    padding: '0',
    pointerEvents: 'auto',
    overflow: 'hidden',
  },
  menuContainer: {
    overflow: 'hidden',
    maxHeight: 'calc(100% - 32px) !important',
  },
  header: {
    display: 'flex',
    color: theme.palette.text.primary,
    justifyContent: 'center',
    alignItems: 'center',
    padding: '12px',
    borderBottomWidth: '1px',
    borderBottomStyle: 'solid',
    borderBottomColor: theme.palette.divider,
    backgroundColor: theme.palette.divider,
  },
  messageItem: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '12px 20px',
    borderBottomWidth: '1px',
    borderBottomStyle: 'solid',
    borderBottomColor: theme.palette.divider,
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  content: {
    paddingLeft: '0.5rem',
  },
  name: {
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightRegular,
  },
  text: {
    margin: '4px 0 0',
    fontSize: '12px',
    color: theme.palette.text.secondary,
    whiteSpace: 'pre-wrap',
  },
  footer: {
    textAlign: 'center',
    padding: '8px',
    fontSize: '12px',
    borderBottom: '1px solid rgba(0, 0, 0, 0.125)',
    cursor: 'pointer',
  },
  orange: {
    backgroundColor: 'orange',
  },
  scrollbar: {
    '& .simplebar-content-wrapper': {
      scrollBehavior: 'smooth',
    },
    '& .simplebar-scrollbar': {
      transition: 'transform 0.2s linear 0s',
    },
  },
}));

const getNotificationStyle = (isUserReadNotification: boolean, darkMode: boolean) => {
  if (isUserReadNotification) {
    return undefined;
  } else {
    return darkMode ? { backgroundColor: '#9e929242' } : { backgroundColor: '#c1f8bca0' };
  }
};

const getLastShowedNotificationIndex = (notificationsLength: number, isShowAll: boolean) =>
  isShowAll ? notificationsLength - 1 : 4;

const getMinMaxMenuHeight = (screenHeight: number) => {
  const maxMenuListHeight = screenHeight >= 885 ? 826 : screenHeight - 59;
  const minMenuListHeight = 250;

  return {
    maxMenuListHeight,
    minMenuListHeight,
  };
};

const NotificationList: React.FC<NotificationListProps> = ({ noticeAnchorEl, onNoticeListClose }) => {
  const classes = useStyles();

  const [_, setNowTime] = useState<number | null>(null);

  const [showedMenuItemsLength, setShowedMenuItemsLength] = useState(0);

  const menuItemsRef = useRef<Array<HTMLLIElement | null>>([]);

  const menuRef = useRef<HTMLDivElement | null>(null);

  const [menuContainerHeight, setMenuContainerHeight] = useState<number | null>(null);

  const { height: screenHeight } = useWindowDimensions();

  const { maxMenuListHeight, minMenuListHeight } = getMinMaxMenuHeight(screenHeight);

  const { notifications } = useSelector(
    (state: RootState) => state.notifications as unknown as INotificationState
  );
  // console.log(notifications);
  const { user } = useSelector((state: RootState) => state.user);
  const { darkMode } = useSelector((state: RootState) => state.theme);

  const history = useHistory();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllNotification(user?.id));
  }, [dispatch, user?.id]);

  useEffect(() => {
    const interval = setInterval(() => setNowTime(new Date().getTime()), 60000);

    return () => clearInterval(interval);
  }, []);

  const unreadNotificationNum = user ? countUnreadNotifications(notifications, user.id) : 0;

  useBodyClick({
    handleClick: (event) => {
      if (menuRef?.current?.contains(event.target as unknown as Node)) {
        return;
      }

      onNoticeListClose();
    },
  });

  const isMenuOpen = Boolean(noticeAnchorEl);

  useEffect(() => {
    const menuItemsHeightSum = countHtmlElementsHeightSum(menuItemsRef.current) + 49;

    let menuContentWrapperHeight =
      menuItemsHeightSum >= maxMenuListHeight ? maxMenuListHeight : menuItemsHeightSum;

    if (menuContentWrapperHeight < minMenuListHeight) {
      menuContentWrapperHeight = minMenuListHeight;
    }

    setMenuContainerHeight(menuContentWrapperHeight);
  }, [showedMenuItemsLength, maxMenuListHeight, minMenuListHeight, screenHeight]);

  return (
    <Menu
      classes={{ list: classes.dropdown, paper: classes.menuContainer }}
      anchorEl={noticeAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      keepMounted={false}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      style={{ pointerEvents: 'none' }}
    >
      <div ref={menuRef}>
        <SimpleBar
          autoHide={true}
          className={classes.scrollbar}
          style={{ height: menuContainerHeight ?? undefined }}
        >
          <Typography className={classes.header}>{`${unreadNotificationNum} нових повідомлень`}</Typography>
          {notifications.map((notification, index) => {
            const { id, title, textContent, updatedAt, orderId, type } = notification;

            const isUserReadThisNotification = user ? isUserReadNotification(notification, user.id) : false;

            const notificationStyle = getNotificationStyle(isUserReadThisNotification, darkMode);

            const handleNotificationRead = () => {
              if (!isUserReadThisNotification) {
                dispatch(notificationRead(id));
              }

              let route = '';

              switch (type) {
                case 'NEW_ORDER':
                  route = `/order/${orderId}`;
                  break;
                case 'NEW_PRODUCT':
                  route = `/product/${orderId}`;
                  break;
                case 'UPDATE_PRODUCT':
                  route = `/product/${orderId}`;
                  break;
                case 'NEW_CATEGORY':
                  route = `/tree-category/${orderId}`;
                  break;
                case 'UPDATE_CATEGORY':
                  route = `/tree-category/${orderId}`;
                  break;
                default:
                  route = '/';
              }

              if (route) {
                history.push(route);
              }

              // orderId && history.push(`/order/${orderId}`);
              onNoticeListClose();
            };

            const nowDateUtc = currentUTCDate(true);
            // console.log(nowDateUtc);
            // console.log(updatedAt);
            const notificationDateLocale = localeDateDiff(nowDateUtc, updatedAt);

            return (
              <MenuItem
                className={classes.messageItem}
                key={id}
                onClick={handleNotificationRead}
                style={notificationStyle}
                ref={(el) => {
                  menuItemsRef.current[index] = el;

                  const lastShowedNotificationIndex = getLastShowedNotificationIndex(
                    notifications.length,
                    true
                  );

                  if (index === lastShowedNotificationIndex) {
                    setShowedMenuItemsLength(index + 1);
                  }
                }}
              >
                <Grid container>
                  <Grid item xs={2} className={classes.iconContainer}>
                    {isUserReadThisNotification ? (
                      <ReceiptIcon fontSize="small" style={{ color: '#9e9292cc' }} />
                    ) : (
                      <ReceiptIcon fontSize="small" style={{ color: '#26bd62' }} />
                    )}
                    {/* <ReceiptIcon fontSize="small" style={{ color: '#26bd62' }} /> */}
                  </Grid>
                  <Grid item xs={10} className={classes.content}>
                    <Typography className={classes.name}>{title}</Typography>
                    <Typography className={classes.text}>{textContent}1</Typography>
                    <Typography className={classes.text}>{notificationDateLocale}</Typography>
                  </Grid>
                </Grid>
              </MenuItem>
            );
          })}
        </SimpleBar>
      </div>
    </Menu>
  );
};

export default NotificationList;
